<template>
    <div class="bindPhone">
        <div class="bindPhone_title">
            <div>绑定手机</div>
        </div>
        <div class="inputPhone">
            <!-- 使用三元运算判断手机号是否有输入，前面的圆点点亮-->
            <div :class="[moblieValue == '' ? 'grayCircle':'colorCircle']" ref="phoneDot"></div>
            <div class="text">手机号码</div>
            <div class="line"></div>
            <!-- 失焦后检查手机号是否为合规的手机号码-->
            <input  v-model="moblieValue" type="tel" :class="[phoneType == '1' ? 'androidStyle':'iosStyle']" @blur="checkMoblie()" placeholder="请输入手机号" maxlength="11" />
        </div>
        <div class="inputCode">
            <div :class="[code == '' ? 'grayCircle':'colorCircle']" ref="codeDot"></div>
            <div class="text">验证码</div>
            <div class="line"></div>
            <input type="text" v-model="code" :class="[phoneType == '1' ? 'androidStyle':'iosStyle']" placeholder="请输入验证码"/>
            <button class="getCode" v-show="show" :disabled="disabled"  @click="getCode()">获取验证码</button>
            <button v-show="!show" disabled class="count">{{count}} S后重发</button>
        </div>

<!--        <div class="errorDiv">-->
<!--            <div v-show="errorShow"  class="errorInfo">-->
<!--                {{msg}}-->
<!--            </div>-->
<!--        </div>-->
        <!-- 手机号输入框与验证码输入框有值才能触发可绑定按钮-->
        <button :disabled="!moblieValue || !code" :class="[moblieValue=='' || code=='' ? 'bind':'binding']"  type="submit" @click="bindMobile" >绑定</button>

    </div>
</template>

<script>
    import {bindMobile, sendCode} from "../../getData/getData";
    import {checkAndroid, checkIOS,openInWebview, responseUtil} from "../../libs/rongxunUtil";
    import {Toast} from "vant";
    //绑定手机失败or成功，传参给android start
    function bindPhoneToAndroid(type) {
        window.himi.bindPhone(type);
    }
    //绑定手机失败or成功，传参给android end
    export default {
        name: "bindPhone",
        data(){
            return{
                phoneType:'1',  //  1 是安卓  2 是ios
                unionid:'',  //app绑定手机使用
                disabled:false,  //获取验证码按钮为可点击状态
                moblieValue:"",  //手机输入框状态
                code:"",    //密码输入框状态
                bindShow:true, //确认按钮为不可点击状态
                show: true, //获取验证码按钮为显示状态
                count: '',   //倒计时秒数
                timer: null,    //倒计时状态
                errorShow:false,    //手机号格式错误提示初始状态--不显示
                msg:"手机号格式有误",

            }
        },
        mounted() {
          this.unionid = this.$route.query.unionid
            if (openInWebview()) {
                if (checkAndroid()) {
                    this.phoneType = '1'
                } else if (checkIOS()) {
                    this.phoneType = '0'
                }
            }
        },
        methods:{
            checkMoblie() {
                var re = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                var phone = this.moblieValue;
                if (phone === ""){  //如果手机号未输入，提示消息不会弹出
                    this.errorShow = false
                }else if (re.test(phone)) { //如果手机号码符合，提示消息不会弹出
                    this.errorShow = false
                    // this.disabled = false
                }else if(!this.errorShow){
                    const TIME_COUNT = 1; //提示消息显示时间为1秒
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                      responseUtil.alertMsg(this,'请输入正确的手机号')
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.errorShow = false;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000)
                    }
                }else {
                  responseUtil.alertMsg(this,'请输入正确的手机号')
                }

            },
            getCode(){
                const TIME_COUNT = 60; //倒计时总秒数为60秒
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }

                let that = this;
                let initData = {}
                initData.mobile = that.moblieValue
                sendCode(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        responseUtil.alertMsg(that,'验证码发送成功')
                        // that.authButtonsList = response.data.data.data
                    })
                })
            },

            //绑定手机号
            bindMobile(){
                // alert("点击绑定")
                let that = this;
                let initData = {};
                initData.type = '1';
                initData.unionid = this.unionid;
                initData.mobile = this.moblieValue;
                initData.code = this.code;
                bindMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code == '0'){
                            if (checkAndroid()) {
                                bindPhoneToAndroid(0);
                            } else if (checkIOS()) {
                                console.log("对ios传参");
                                window.webkit.messageHandlers.bindPhone.postMessage({body: 0});
                            }
                        }else{
                            if (checkAndroid()) {
                                bindPhoneToAndroid(1);
                            } else if (checkIOS()) {
                                console.log("对ios传参");
                                window.webkit.messageHandlers.bindPhone.postMessage({body: 1});
                            }
                        }
                    })
                })

            }

        }
    }
</script>

<style scoped>
    /*父级div*/
    .bindPhone{
        width: 100%;
    }
    /*手机号码输入错误信息提示*/
    .errorDiv{
        float: left;
        margin-left: 7.5%;
        height: 2rem;
        width: 8rem;
        margin-top: 1rem;
    }
    .errorInfo{
        padding-left: 0.15rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin: 0 auto;
        color:white;
        border-radius: 0.1rem;
        width: 2.5rem;
        background-color:black;
        opacity: 0.55;
        font-size: 12px;

    }
    /*手机绑定的页面顶部的div样式*/
    .bindPhone_title{
        background: url("../../assets/images/head-quotation-mark.png") left no-repeat;
        background-size: 27% 100%;
        width: 6rem;height:2rem;
        border: 1px solid #f8f8f8;
        margin-top: 1.8rem;
        margin-bottom: 0.5rem;

    }
    .bindPhone_title div{
        height:1rem;
        width: 4rem;
        margin-top: 0.95rem;
        font-size: 25px;
        font-weight: bold;
        margin-left: 0.44rem;
    }

    /*圆点样式*/
    .colorCircle{
        border-radius: 50%; /*设置圆点与其后面的内容水平显示*/
        width: 0.22rem;          /*设置圆点的宽、高、背景颜色，再设置圆点半径所占百分比*/
        height: 0.22rem;
        background-color: #ff5D3B;
        float: left;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        margin-top: 0.65rem;
    }
    .grayCircle{
        width: 0.22rem;
        height: 0.22rem;
        background-color: #C7C7C7;
        border-radius: 50%;
        float: left;
        margin-right: 0.2rem;
        margin-left: 0.35rem;
        margin-top: 0.65rem;
    }
    /*竖线样式*/
    .line {
        height: 0.33rem;
        float: left;
        border: #D8D8D8 solid 0.5px;
        margin-left: 0.23rem;
        margin-right: 0.22rem;
        margin-top: 0.6rem
    }
    /*label样式*/
    .text{
        font-size: 15px;
        float: left;
        margin-top: 0.5rem;
        font-weight: bold;
    }
    /*验证码输入框Div*/
    .inputCode {
        float: left;
        margin-top:20px;
        height:1.4rem;
        width: 92%;
        margin-left: 4%;
        border-radius: 0.15rem;/*圆角*/
        background: white;
    }
    /*手机号码输入框*/
    .androidStyle {
        float: left;
        width:3.5rem;
        height:0.5rem;
        /*height:0.58rem;*/
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 0.5rem;
    }
    /*手机号码输入框DIV*/
    .inputPhone{
        /*margin-top:20px;*/
        float: left;
        height:1.4rem;
        margin-bottom: 0.05rem;
        width: 92%;
        margin-left: 4%;
        border-radius: 0.15rem;/*圆角*/
        background: white;
    }

    /*输入框*/
    .iosStyle {
        float: left;
        width:3.2rem;
        height:0.5rem;
        /*height:0.58rem;*/
        vertical-align:middle;
        border: none;
        font-size: 16px;
        margin-top: 15px;
    }

    input[type="text"], input[type="button"], input[type="submit"], input[type="reset"],input[type="tel"] select, textarea,button {
        -webkit-appearance: none;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 100px white inset;
    }
    /*属性placeholder样式*/
    /*input::-webkit-input-placeholder{*/
    /*    color: #D8D8D8;*/
    /*    font-family: PingFangSC-Regular;*/
    /*    font-size: 15px;*/
    /*    text-align: left;*/
    /*    !*transform:translateY(5%);!*-50文字居中*!*!*/
    /*}*/
    /*获取验证码按钮*/
    .getCode{
        float: left;
        color:  #FF5D3B;
        border-radius: 0.2rem;
        /*background-color: #f8f8f8;*/
        font-size: 13px;
        height: 0.85rem;
        width: 2.7rem;
        border: 0.02rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        margin-top: 0.289rem;
    }
    /*重发验证码时间按钮*/
    .count{
        float: left;
        color:  white;
        border-radius: 0.2rem;
        /*background-color: #f8f8f8;*/
        font-size: 13px;
        height: 0.85rem;
        width: 2.7rem;
        border: 0.02rem solid transparent;
        background-color:#222222;
        opacity: 0.2;
        margin-top: 0.289rem;
    }

    /*按钮不可点击绑定样式*/
    .bind{
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 4%;
        height: 1.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        background:rgba(184, 184, 184, 0.2);
        margin-top: 4.5rem;
    }
    /*可以点击绑定的样式*/
    .binding{
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 3%;
        height: 1.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        margin-top: 4.5rem;
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;/*渐变色*/
    }

</style>
